import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";
import contractServices from "../../services/contract/contractServices";
import DataTable from "react-data-table-component";
import FileSaver from "file-saver";
import download from "downloadjs";
import distributorService from "../../services/distribitor/distributorService";
import { set } from "date-fns";
import LoadingPage from "../../components/LoadingPage";
const VenteGlobalD = () => {
  const history = useHistory();
  const [contractList, setContractList] = useState();
  const [revfils, setRevfils] = useState(
    localStorage.getItem("parent") === "null" ? true : false
  );
  const id = localStorage.getItem("idDist");
  const sousRv = JSON.parse(localStorage.getItem("sous-rev"));
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("role") !== "Distributeur") {
      history.goBack();
    }
    if (sousRv) {
      contractServices.getAllcontractsParentandDist(id).then(async (res) => {
        setLoading(true);
        let array = [];
        for (let index = 0; index < res.data.length; index++) {
          let el = res.data[index];
          if (el.disrtibutor !== id) {
            const res = await distributorService.getDisrtibutorbyId(
              el.disrtibutor
            );
            el.subRev = res.data.socialReason;
            array.push(el);
          } else {
            const res = await distributorService.getDisrtibutorbyId(
              el.disrtibutor
            );
            el.subRev = res.data.socialReason;
            array.push(el);
          }
        }

        setContractList(array);
        setLoading(false);
      });
    } else {
      setLoading(true);
      contractServices.getAllContractbyDisributor(id).then((res) => {
        setContractList(res.data);
        setLoading(false);
      });
    }
  }, []);
  const downloadXlsx = (data) => {
    download(atob(data), "data.xlsx", {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  };
  const columns = [
    {
      name: "N° Contrat",
      selector: (row) => (sousRv ? row.numContract == 0 ? row.numInclusion : row.numContract : row.num),
      sortable: true,
    },
    {
      name: "Nom Prénom",
      selector: (row) =>
        sousRv
          ? row.lastName + " " + row.firstName
          : row.customer.lastName + " " + row.customer.firstName,
      sortable: true,
    },
    {
      name: "Prime Net Garanty TTC",
      selector: (row) => row.netPriceTtc?.toFixed(3),
      sortable: true,
    },
    {
      name: "Marge distributeur TTC",
      selector: (row) => (revfils ? row.margeDistributeurTtc?.toFixed(3) : ""),
      sortable: true,
    },
    {
      name: "Marge revendeur TTC",
      selector: (row) => row.margeRevendeurTtc?.toFixed(3),
      sortable: true,
    },
    {
      name: "Primes à reversées",
      selector: (row) =>
        (
          row.totalAmount -
          row.margeDistributeurTtc -
          row.margeRevendeurTtc
        ).toFixed(3),
      sortable: true,
    },
    sousRv
      ? {
          name: "Revendeur",
          cell: (row) => {
            console.log(row);
            return <Badge color="success">{row.subRev}</Badge>;
          },
          sortable: true,
        }
      : "",
  ];
  const exporter = () => {
    setLoading(true);
    contractServices.xlExport(id).then((res) => {
      downloadXlsx(res.data);
      setLoading(false);
    });
  };
  return (
    <div>
      <Breadcrumb
        parent={"Distributeur / État des ventes"}
        title={"État Global"}
      />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader className="m-0  align-button">
            <Row>
              <Col className="text-right">
                <Button
                  className="btn-pill btn-air-primary "
                  color="primary"
                  onClick={exporter}
                >
                  {"Exporter"}
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="pt-2">
            {loading ? (
              <LoadingPage />
            ) : (
              <DataTable
                noHeader
                pagination
                paginationComponentOptions={{
                  rowsPerPageText: "Ligne Par Page",
                  rangeSeparatorText: "/",
                  selectAllRowsItem: true,
                  selectAllRowsItemText: "all",
                }}
                columns={columns}
                data={contractList || []}
                noDataComponent={<div>Aucun enregistrement à afficher</div>}
                pointerOnHover={true}
                progressPending=""
                progressComponent={
                  <div className="loader-box">
                    <div className="loader-1"></div>
                  </div>
                }
              ></DataTable>
            )}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default VenteGlobalD;
