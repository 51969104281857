import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import IconButton from "@material-ui/core/IconButton";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Badge,
} from "reactstrap";

import Breadcrumb from "../../../layout/breadcrumb";
import { useHistory, useLocation } from "react-router-dom";
import SinitreServices from "../../../services/sinistre/SinistreServices";
import LoadingPage from "../../../components/LoadingPage";

const SinisterListInclusion = () => {
  const history = useHistory();
  const location = useLocation();
  const back = location.state?.back;
  const role = localStorage.getItem("role");
  const profil = localStorage.getItem("profil");
  const [listSinister, setListSinister] = useState([]);
  const [originList, setOriginList] = useState([]);
  const [handleFilter, setHandleFilter] = useState(true);
  const [filteredValues, setFilteredValues] = useState({
    numSinistre: "",
    numContract: "",
    cin: "",
    family: "",
    product: "",
    status: "",
    date: "",
  });
  const statusList = {
    1: "Régler",
    2: "Rejeter",
    0: "En cours de traitement",
    3: "Clôturé",
  };
  const [loading, setLoading] = useState(false);
  const handleFilterValues = (value, name) => {
    setFilteredValues({ ...filteredValues, [name]: value });
  };
  useEffect(() => {
    setLoading(true);
    SinitreServices.getAllSinistreInclusion(localStorage.getItem("id")).then((res) => {
      setListSinister(res.data);
      setOriginList(res.data);
      setLoading(false);
    });
  }, []);

  const filterData = () => {
    // console.log(filteredValues);
    const filtredList = originList.filter((el) => {
      return (
        String(el.num).includes(filteredValues?.numSinistre) &&
        String(el.contractProduct.contract.numInclusion).includes(
          filteredValues?.numInclusion
        ) &&
        String(el.contractProduct.contract.customer.cin).includes(
          filteredValues?.cin
        ) &&
        el.contractProduct.product.name
          .toLowerCase()
          .includes(filteredValues.family.toLowerCase()) &&
        el.contractProduct.contract.formula.name
          .toLowerCase()
          .includes(filteredValues.product.toLowerCase()) &&
        (filteredValues.date
          ? new Date(el.date).toLocaleDateString() ==
            new Date(filteredValues.date).toLocaleDateString()
          : true)
        // &&(filteredValues.status != 0 ? el.status == filteredValues.status : true)
      );
    });
    // console.log(filtredList);
    setListSinister(filtredList);
  };

  const columns = [
    {
      name: "N°",
      selector: "num",
      sortable: true,
    },
    {
      name: "N° Contrat",
      selector: "contractProduct.contract.numInclusion",
      sortable: true,
    },
    {
      name: "Client",
      selector: "contractProduct.contract.customer.firstName",
      sortable: true,
    },
    {
      name: "Famille/produit",
      selector: "contractProduct.product.name",
      sortable: true,
      cell: (row) => {
        return (
          row.contractProduct.product.category.family.familyName +
          " / " +
          row.contractProduct.contract?.formula.name
        );
      },
    },
    {
      name: "Statut",
      cell: (row) => (
        <Badge
            color={
              row.status === 1
                ? "success"
                : row.status === 2
                ? "danger"
                : row.status === 3
                ? "primary"
                : "warning"
            }
          >
            {statusList[row.status]}
          </Badge>
      ),
    },
    {
      name: "Date",
      selector: "date",
      sortable: true,
      cell: (row) => new Date(row.date).toLocaleDateString("fr"),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          {role === "Courtier " ||
          role === "Assurance " ||
          profil === "User" ? (
            <>
              <IconButton
                color="primary"
                onClick={() => {
                  history.push({
                    pathname: "/gest_sinistre/sinistre/update_sinistre_inclusion",
                    state: { row, preview: true },
                  });
                }}
              >
                <i className="icofont icofont-eye-alt"></i>
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                color="primary"
                onClick={() => {
                  history.push({
                    pathname: "/gest_sinistre/sinistre/update_sinistre_inclusion",
                    state: { row, update: true },
                  });
                }}
              >
                <i className="icofont icofont-ui-edit"></i>
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {
                  history.push({
                    pathname: "/gest_sinistre/sinistre/update_sinistre_inclusion",
                    state: { row, preview: true },
                  });
                }}
              >
                <i className="icofont icofont-eye-alt"></i>
              </IconButton>
            </>
          )}
        </div>
      ),

      button: true,
    },
  ];

  return (
    <div>
      <Breadcrumb parent={"Sinistre"} title={"Liste des Sinistres"} />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader
            className="ml-2"
            style={{ cursor: "pointer", padding: "10px" }}
            onClick={() => {
              setHandleFilter(!handleFilter);
            }}
          >
            <span className="float-left">
              <h6 className="mt-1">{"Filtrer"}</h6>
            </span>

            <span className="float-right ">
              <h4>
                {handleFilter ? (
                  <i className="fa fa-angle-down"></i>
                ) : (
                  <i className="fa fa-angle-up"></i>
                )}
              </h4>
            </span>
          </CardHeader>

          <CardBody className="pt-0" hidden={handleFilter}>
            <Form className="theme-form">
              <Row>
                <Col md="3 mb-2">
                  <FormGroup>
                    <Label className="col-form-label ">N° Sinistre</Label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "numSinistre")
                      }
                      className="form-control"
                      type="number"
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <Label className="col-form-label "> N° Contrat </Label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "numInclusion")
                      }
                      className="form-control"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label"> Cin/MF</label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "cin")
                      }
                      className="form-control"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label"> Famille</label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "family")
                      }
                      className="form-control"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label"> Produit</label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "product")
                      }
                      className="form-control"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label"> Date</label>
                    <DatePicker
                      className="form-control digits"
                      selected={filteredValues.date || ""}
                      onChange={(e) => {
                        handleFilterValues(e, "date");
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-3">
                  <FormGroup>
                    <Label className="col-form-label">Statut</Label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control digits"
                      defaultValue={0}
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "status")
                      }
                    >
                      <option value={0}>{"Tout"}</option>

                      <option value={"V"}>{"Validé"}</option>
                      <option value={"B"}>{"Brouillon"}</option>
                      <option value={"AS"}>{"Attente signature"}</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col>
                <Button
                  onClick={filterData}
                  className="btn-pill btn-air-success"
                  color="success"
                >
                  Recherche
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader
            className="b-l-primary align-button"
            style={{
              padding: "15px",
              textAlign: "end",
            }}
          >
            {role === "FrontOffice" || profil === "User" ? (
              <></>
            ) : (
              <Button className="ml-3" color="success" outline>
                Exporter
              </Button>
            )}

            {role === "Courtier " ||
            role === "Assurance " ||
            profil === "User" ? (
              <></>
            ) : (
              <Col className="text-right ">
                <span>
                  <Button
                    className="btn-pill btn-air-primary "
                    color="primary"
                    onClick={() => {
                      history.push({
                        pathname: "/gest_sinistre/sinistre/update_sinistre_inclusion",
                        state: { preview: false, update: true },
                      });
                    }}
                  >
                    + {"Déclarer un sinistre"}
                  </Button>
                </span>
                &nbsp;
              </Col>
            )}
          </CardHeader>

          <CardBody className="pt-2">
            {loading ? (
              <LoadingPage />
            ) : (
              <DataTable
                noHeader
                pagination
                paginationComponentOptions={{
                  rowsPerPageText: "Afficher",
                  rangeSeparatorText: "/",
                  selectAllRowsItem: true,
                  selectAllRowsItemText: "all",
                }}
                columns={columns}
                data={back ? location.state.row : listSinister}
                pointerOnHover={true}
                progressPending=""
                progressComponent={
                  <div className="loader-box">
                    <div className="loader-1"></div>
                  </div>
                }
              ></DataTable>
            )}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default SinisterListInclusion;
