import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useMemo,
  useRef,
} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Media,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import ImageViewer from "react-simple-image-viewer";

import { useHistory } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import { findLastIndex } from "lodash";
import dataForm from "./formSmart.json";
import dataFormG from "./formGlass.json";

import { Avatar } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { fetchFamily } from "../../../redux/family/familyActions";
import { fetchFormula } from "../../../redux/formula/formulaActions";
import DatePicker from "react-datepicker";
import contractServices from "../../../services/contract/contractServices";
import SinistreServices from "../../../services/sinistre/SinistreServices";
import Select from "react-select";
import { fetchUsers } from "../../../redux/users/usersActions";
import productService from "../../../services/product/productService";
import { format, subDays } from "date-fns";
import usersService from "../../../services/users/usersService";
const UpdateSinistreInclusion = ({ location }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const refClient = useRef();
  const refContract = useRef();

  const refTypologies = useRef();

  console.log(refClient.current);
  const update = location.state?.update;
  const preview = location.state?.preview;
  console.log(subDays(new Date(), -1));
  const row = location.state?.row;
  // const type = row?.contractProduct?.product?.category.name;
  const [type, setType] = useState(
    row?.contractProduct?.product?.category.name
  );

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const [images, setImages] = useState([]);
  const [facture, setFacture] = useState(null);
  const [file, setFile] = useState(null);

  const [listContract, setListContract] = useState([]);

  const [typologie, setTypologie] = useState([]);
  const [client, setClient] = useState(null);
  const [selectedTypologies, setSelectedTypologie] = useState(null);

  const [listUsers, setListUsers] = useState([]);
  const listFormula = useSelector(
    ({ formulaReducers }) => formulaReducers.formulas
  );
  console.log(listFormula);
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  React.useEffect(() => {
    dispatch(fetchFormula());
    usersService
      .getAllUsersInclusion(localStorage.getItem("id"))
      .then((res) => setListUsers(res.data));
    dispatch(fetchUsers());
    if ((preview || update) && row) {
      const clientData = row.contractProduct.contract.customer;
      const listTypologies =
        row.contractProduct.contract.formula.breakdownFormulas.map((el) => ({
          value: el.breakdownType.idbreakdown,
          label: el.breakdownType.name,
        }));
      setClient({
        value: clientData.idcustomer,
        label: `${clientData.civility || ""} ${clientData.firstName} ${
          clientData.lastName
        }`,
        data: clientData,
      });
      setTypologie(listTypologies);
      reset({
        ...row,
        idcontract: {
          value: row.contractProduct.contract.idcontract,
          label: row.contractProduct.contract.numInclusion,
          data: row.contractProduct.contract,
        },
        client: {
          value: clientData.idcustomer,
          label: `${clientData.civility || ""} ${clientData.firstName} ${
            clientData.lastName
          }`,
          data: clientData,
        },
        selectedTypologies: [],
        constatedate: row.constatedate
          ? new Date(row.constatedate).toISOString().slice(0, 10)
          : "",
      });
    }
    if (row?.facturephoto) {
      const facArray = row.facturephoto.split("/");
      setFacture({
        name: facArray[facArray.length - 1],
        path: row.facturephoto,
      });
    }
    if (row?.prodphoto) {
      const array = row.prodphoto.split(",");
      setImages(array.filter(Boolean));
    }
  }, []);
  const uploadFiles = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let uploadedImage1 = "";
        let uploadedImage2 = "";
        let uploadFacture = "";
        let promises = [];

        if (file) {
          promises.push(
            contractServices.uploadFile(file[0]).then((res) => {
              uploadFacture = res.data;
            })
          );
        }
        if (getValues("img1")) {
          promises.push(
            contractServices.uploadFile(getValues("img1")[0]).then((res) => {
              uploadedImage1 = res.data;
            })
          );
        }
        if (getValues("img2")) {
          promises.push(
            contractServices.uploadFile(getValues("img2")[0]).then((res) => {
              uploadedImage2 = res.data;
            })
          );
        }

        Promise.all(promises).then((res) => {
          resolve({ uploadedImage1, uploadedImage2, uploadFacture });
        });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

  React.useEffect(() => {
    if (client?.value)
      contractServices
        .getAllcontractswithoutpaginationByUser(client.value)
        .then((res) => {
          console.log(client.value);
          console.log(res);
          setValue("selectedTypologies", []);
          setListContract(
            res?.data?.map((el) => ({
              value: el.idcontract,
              label: el.numInclusion,
              data: el,
            }))
          );
        });
  }, [client]);
  console.log(format(subDays(new Date(), 0), "yyyy-MM-dd"));
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
  } = useForm();

  const onSubmit = async (dataToSend) => {
    if (!client) {
      refClient.current?.focus();
      return;
    }
    if (!dataToSend.idcontract) {
      refContract.current?.focus();
      return;
    }
    if (selectedTypologies.length === 0) {
      refTypologies.current?.focus();
      return;
    }
    if (file && getValues("img1") && getValues("img2")) {
      var resUploadFiles = await uploadFiles();
    }
    //     uploadFacture: "http://197.13.17.43:8080/documents/sample.pdf"
    // uploadedImage1: "http://197.13.17.43:8080/documents/Sans titre.png"
    // uploadedImage2: "http://197.13.17.43:8080/documents/serveur.png"

    let data = {
      ...dataToSend,
      listIdBreakDownType: selectedTypologies.map((el) => el.value),
    };
    if (resUploadFiles?.uploadFacture)
      data.facturephoto = resUploadFiles.uploadFacture;
    if (resUploadFiles?.uploadedImage1 || resUploadFiles?.uploadedImage2)
      data.prodphoto =
        resUploadFiles.uploadedImage1 +
        "," +
        resUploadFiles.uploadedImage2 +
        ",";

    if (data.contractProduct?.idcontractProduct)
      data.idCP = data.contractProduct?.idcontractProduct;
    else {
      const res = await productService.getcontractProductByIdContract(
        data.idcontract.value
      );
      if (res.status === 200) {
        console.log("res.data.idcontractProduct", res);
        data.idCP = res.data[0].idcontractProduct;
      } else return;
    }
    delete data.client;
    delete data.contractProduct;
    delete data.selectedTypologies;
    delete data.img1;
    delete data.img2;

    console.log({ data });
    const response = await SinistreServices.createSinistre({...data, inclusion:1});
    console.log({ response });
    if (response.status == 201) {
      history.push("/gest_sinistre/sinistre_inclusion");
    }
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Sinistre"
        title={
          update && row
            ? "Modification d'un sinistre"
            : update
            ? "Création sinistre"
            : "Sinistre"
        }
      />
      <Container fluid={true}>
        {isViewerOpen && (
          <ImageViewer
            src={images}
            currentIndex={currentImage}
            disableScroll={true}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
        <Form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col>
              <Card className="shadow-lg p-0 shadow-showcase">
                <CardHeader
                  className="b-l-primary"
                  style={{
                    padding: "15px",
                    textAlign: "end",
                  }}
                >
                  {/* <Button
                    disabled={!client}
                    onClick={() => {
                      history.push({
                        pathname: "/gest_client_contrat/clients/Update_client",
                        state: {
                          back: true,
                          row: {
                            ...client.data,
                          },
                        },
                      });
                    }}
                    color="primary"
                    outline
                  >
                    Fiche Client
                  </Button> */}
                  {/* <Button
                    disabled={!getValues("idcontract")}
                    onClick={() => {
                      history.push({
                        pathname: "/vueContrat",
                        state: {
                          row: {
                            idcontract: getValues("idcontract").value,
                          },
                        },
                      });
                    }}
                    className="ml-3"
                    color="success"
                    outline
                  >
                    Contrat
                  </Button> */}
                </CardHeader>
                <CardBody className="pt-1">
                  <div className="form-row">
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Client
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Select
                        ref={refClient}
                        value={getValues("client")}
                        isDisabled={!!row}
                        onChange={(val) => {
                          console.log({ val });
                          setValue("idcontract", "");
                          setClient(val);
                          setValue("client", val);
                        }}
                        options={listUsers.map((el) => ({
                          value: el.idcustomer,
                          label: `${el.civility || ""} ${el.firstName} ${
                            el.lastName
                          }`,
                          data: el,
                        }))}
                      />

                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Num Contrat
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Select
                        ref={refContract}
                        value={getValues("idcontract")}
                        isDisabled={!!row}
                        onChange={(val) => {
                          console.log({ val });
                          setValue("idcontract", val);
                          setType(val.data.family.toLowerCase());
                          contractServices
                            .getContractbyId(val.value)
                            .then((res) => {
                              console.log(res);
                              setValue("idFormula", res.data.formula.idformula);
                              setTypologie(
                                res.data.formula.breakdownFormulas.map(
                                  (el) => ({
                                    value: el.breakdownType.idbreakdown,
                                    label: el.breakdownType.name,
                                  })
                                )
                              );
                            });
                        }}
                        options={listContract}
                      />

                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Choisissez la typologie du sinistre
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Select
                        ref={refTypologies}
                        value={selectedTypologies}
                        // value={selectedTypologies}
                        onChange={(e) => {
                          setValue("selectedTypologies", e);
                          setSelectedTypologie(e);
                        }}
                        isMulti
                        options={typologie}
                        isDisabled={!update}
                      ></Select>
                      <span></span>
                    </Col>
                    {row && (
                      <Col md="6 mb-3">
                        <Label htmlFor="validationCustom01">
                          Num Sinistre
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <input
                          className="form-control"
                          name=" num_sinis"
                          type="text"
                          placeholder=" Num Sinistre"
                          {...register("num", { required: true })}
                          disabled={update || preview}
                        />
                        <span></span>
                      </Col>
                    )}
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Produit
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      {/* <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        {...register("contractProduct.product.name", {
                          required: true,
                        })}
                        disabled={!update}
                      /> */}
                      <select
                        className="form-control"
                        name="produit"
                        type="select"
                        // placeholder="Produit"
                        {...register("idFormula", {
                          required: true,
                        })}
                        disabled={!update}
                      >
                        <option value="">Select... </option>
                        {listFormula?.map((el) => (
                          <option value={el.idformula}>{el.name}</option>
                        ))}
                      </select>
                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Date
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <input
                        className="form-control"
                        name="date"
                        type="date"
                        max={format(subDays(new Date(), 0), "yyyy-MM-dd")}
                        min={format(subDays(new Date(), 4), "yyyy-MM-dd")}
                        {...register("date", {
                          required: true,
                        })}
                        disabled={!update}
                      />
                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Status
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      {/* <input
                        className="form-control"
                        name="status"
                        type="text"
                        placeholder="Status"
                        innerRef=""
                        disabled={!update}
                      /> */}
                      <select
                        className="form-control"
                        name="produit"
                        type="select"
                        // placeholder="Produit"
                        {...register("status", {
                          required: true,
                        })}
                        disabled={!update}
                      >
                        <option value={""}>Select...</option>
                        <option value={1}>Validé</option>
                        <option value={0}>Non validé</option>
                      </select>
                      <span></span>
                    </Col>
                    {/* <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Localisation
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <input
                        className="form-control"
                        name="localisation"
                        type="text"
                        placeholder="Localisation"
                        innerRef=""
                        disabled={!update}
                        {...register("location", {
                          required: true,
                        })}
                      />
                      <span></span>
                    </Col> */}
                  </div>
                </CardBody>
              </Card>
              {type && (
                <Card className="shadow-lg p-0 shadow-showcase">
                  <CardHeader className="b-l-primary pb-2">
                    {/* <h5>Info Generale</h5> */}
                  </CardHeader>

                  <CardBody className="pt-1">
                    <div className="form-row mt-2">
                      {(type.includes('Optique') ? dataFormG : dataForm).map(
                        (el) => (
                          <Col md="6 mb-3">
                            <Label
                              htmlFor="validationCustom01"
                              className="mt-2 mb-1"
                            >
                              {el.label}
                              <span style={{ color: "red" }}>*</span>
                            </Label>
                            {el.type == "text" && (
                              <input
                                className="form-control"
                                type="text"
                                // value={row[el.name]}
                                {...register(el.name, {
                                  required: true,
                                })}
                                disabled={!update}
                              />
                            )}
                            {el.type == "select" && (
                              <select
                                className="form-control"
                                type="text"
                                // value={row[el.name]}
                                {...register(el.name, {
                                  required: true,
                                  validate: (value) => value != 0,
                                })}
                                disabled={!update}
                              >
                                <option value={""}>{"Select..."}</option>
                                {el.list.map((val) => (
                                  <option value={val}>{val}</option>
                                ))}
                              </select>
                            )}
                            {el.type == "date" && (
                              <input
                                className="form-control"
                                type="date"
                                // name="date"
                                {...register(el.name, {
                                  required: true,
                                })}
                                disabled={!update}
                              />
                            )}
                            {el.type == "image" && (
                              <Row>
                                <Col md={images[el.position] ? "10" : "12"}>
                                  <input
                                    className="form-control"
                                    type="file"
                                    {...register(el.name, {
                                      // required: !images[el.position],
                                      onChange: (e) => {
                                        let imgs = [...images];
                                        imgs[el.position] = e.target.files[0]
                                          ? URL.createObjectURL(
                                              e.target.files[0]
                                            )
                                          : null;
                                        setImages(imgs);
                                      },
                                    })}
                                    disabled={!update}
                                  />
                                </Col>

                                {images[el.position] ? (
                                  <Col md="2" className="align-self-center">
                                    <div
                                      className="avatar ratio"
                                      style={{
                                        // width: "40px",
                                        height: "40px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <Media
                                        body
                                        className="img-40 rounded-circle"
                                        onClick={() =>
                                          openImageViewer(el.position)
                                        }
                                        src={
                                          images[el.position]
                                          // ? typeof images[el.position] ===
                                          //   "string"
                                          //   ? images[el.position]
                                          //   : URL.createObjectURL(
                                          //       images[el.position]
                                          //     )
                                          // : ""
                                        }
                                      />
                                    </div>
                                  </Col>
                                ) : null}
                              </Row>
                            )}
                            {el.type == "file" && (
                              <input
                                // required={!facture}
                                className="form-control"
                                type="file"
                                // name="date"
                                // {...register(el.name, {
                                //   required: true,
                                // })}
                                onChange={(e) => setFile(e.target.files)}
                                disabled={!update}
                              />
                            )}
                            {el.type == "file" && facture && update && (
                              <div style={{ marginTop: "10px" }}>
                                <a
                                  target="_blank"
                                  href={facture?.path}
                                  download={facture.name}
                                >
                                  {facture.name}
                                </a>
                              </div>
                            )}
                            <span></span>
                          </Col>
                        )
                      )}
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                color="danger"
                type="reset"
                className="float-left"
                onClick={() => history.goBack()}
              >
                Retour
              </Button>
            </Col>
            <Col>
              {update && (
                <Button color="primary" type="submit" className="float-right">
                  Valider
                </Button>
              )}
            </Col>
          </Row>
          {/* <Button color="primary">{config.button}</Button>
                  <Button color="primary">{config.button}</Button> */}
        </Form>
      </Container>
    </Fragment>
  );
};

export default UpdateSinistreInclusion;

{
  /* <DatePicker
                                className="form-control digits"
                                selected={
                                  getValues("constatedate")
                                    ? new Date(getValues("constatedate"))
                                    : ""
                                }
                                {...register("constatedate", {
                                  required: true,
                                })}
                                onChange={(e) => {
                                  console.log(e);
                                  setValue("date", "2022-01-01");
                                }}
                              /> */
}
