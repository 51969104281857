import React, { useEffect } from "react";
import Breadcrumbs from "../../../layout/breadcrumb";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Select from "react-select";
import LoadingPage from "../../../components/LoadingPage";
import DataTable from "react-data-table-component";
import { useHistory, useLocation } from "react-router-dom";
import { useState } from "react";
import moment from "moment";
import contractServices from "../../../services/contract/contractServices";
import familyService from "../../../services/family/familyService";
import formulaService from "../../../services/formula/formulaService";
import productService from "../../../services/product/productService";
import { IconButton } from "@material-ui/core";
import DatePicker from "react-datepicker";
import download from "downloadjs";
import distributorService from "../../../services/distribitor/distributorService";
const statusList = {
  V: "Validé ",
  B: "Brouillon",
  AS: "Attente signature",
  AP: "Attente paiement",
  E: "Expirer",
  C: "Annulé",
};
const ContractInclusionList = () => {
  const history = useHistory();
  const location = useLocation();
  const back = location?.state?.back;
  const role = localStorage.getItem("role");
  const profil = localStorage.getItem("profil");
  const [listContractInclusion, setListContractInclusion] = useState([]);
  const [option, setListOption] = useState([]);
  const [selectOption, setOptionSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formula, setFormula] = useState({});
  const [Vert, setVert] = useState(false);
  const [file, setFile] = useState(null);
  const [pay, setPay] = useState([]);
  const [listDistributors, setListDistributors] = useState([]);
  const [handleFilter, setHandleFilter] = useState(true);
  const [listCF, setListCF] = useState([]);
  const [listContract, setListContract] = useState([]);
  const [filteredValues, setFilteredValues] = useState({
    cin: "",
    dateDebut: "",
    dateFin: "",
    famille: "",
    firstName: "",
    formula: "",
    idUser: localStorage.getItem("id"),
    lastName: "",
    idDistributor: null,
    numContract: "",
    status: "",
    tax: "",
    inclusion: 1,
  });
  const Verttoggle = () => {
    setVert(!Vert);
  };
  const onchangeSelect = (item) => {
    setOptionSelected(item);
    console.log(item);
  };
  const handleFilterValues = (value, name) => {
    setFilteredValues({ ...filteredValues, [name]: value });
  };
  useEffect(() => {
    setLoading(true);
    if(localStorage.getItem("id") === "6a7d1b9c-254c-455e-a186-6b2a37dd231f"){
      contractServices
      .filterContract({
        cin: "",
        dateDebut: "",
        dateFin: "2024-11-28T00:00:00.000Z",
        famille: "",
        firstName: "",
        formula: "",
        idUser: "4027c224-04fe-4542-be2d-42193bb47407",
        lastName: "",
        numContract: "",
        status: "",
        tax: "",
        inclusion: 1,
      })
      .then((res) => {
          setListContractInclusion(res.data.sort((a, b) => b.numInclusion - a.numInclusion));
          setLoading(false);
      });
    }
    contractServices
      .filterContract({
        cin: "",
        dateDebut: "",
        dateFin: "",
        famille: "",
        firstName: "",
        formula: "",
        idUser: localStorage.getItem("id"),
        lastName: "",
        numContract: "",
        status: "",
        tax: "",
        inclusion: 1,
      })
      .then((res) => {
        if(localStorage.getItem("id") === "01d9b468-de87-4861-a70e-0c0120542fa2" || localStorage.getItem("id") === "d0df3ce6-a577-4fc4-a4c1-598b97800b40"){
          setListContractInclusion(res.data.filter((el) => el.seqAssurance === "76 2024 0008").sort((a, b) => b.numInclusion - a.numInclusion));
          setLoading(false);
        }else{
          setListContractInclusion(res.data.sort((a, b) => b.numInclusion - a.numInclusion));
          setLoading(false);

        }
      });
    familyService.getAllFamily().then((res) => {
      let dataFiltred = res.data.filter((el) => el.familyName === "Optique");
      if (dataFiltred) {
        familyService
          .getFamilybyParentId(dataFiltred[0]?.idfamily)
          .then((res) => {
            console.log(res.data);
            if (res) {
              formulaService
                .getAllformulasbyFamily(res.data[0]?.idfamily)
                .then((res) => setFormula(res.data[0]));
            }
          });
      }
    });
    productService.getAllProduct().then(res=>{
      if (res.data){
        console.log(res.data);
        setListOption(
          res.data?.map((el) => ({
            value: el.idproduct,
            label: el.name,
          }))
        );
      }
    })
    distributorService
          .getAllDistributors(localStorage.getItem("id"))
          .then((response) => {
            setListDistributors(response.data);
          });
  }, []);
  const handleSubmit = () => {
    contractServices
      .importContractsInclusion(
        file,
        formula?.fomula?.idformula,
        selectOption.value,
        localStorage.getItem("id")
      )
      .then((res) => {
        if (res.status === 200) {
            setVert(!Vert);
            window.location.reload();
        }
        
      });
  };
  const downloadXlsx = (data) => {
    download(atob(data), "data.xlsx", {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  };
  const filterData = async () => {
    setLoading(true);
      const filtredList = (
        await contractServices.filterContract({
          ...filteredValues,
          dateDebut: moment(filteredValues.dateDebut).add(1, "hour"),
          dateFin: moment(filteredValues.dateFin).add(1, "hour"),
          idDistributor:
            filteredValues.idDistributor === "Tout"
              ? null
              : filteredValues.idDistributor,
        })
      ).data;
      setListContractInclusion(filtredList.sort((a, b) => b.numInclusion - a.numInclusion));
      setLoading(false);
    };
  const columns = [
    {
      name: "N°",
      // selector: (row) => (back ? row.num : row.numInclusion),
      selector: (row) => (back ? row.numInclusion : row.numInclusion),
      sortable: true,
    },
    {
      name: "N° Contrat",
      // selector: (row) => (back ? row.num : row.numInclusion),
      selector: (row) => (back ? row.seqAssurance : row.seqAssurance),
      sortable: true,
    },
    {
      name: "Nom",
      selector: (row) =>
        back
          ?row.customer.lastName
          : row.lastName,
      sortable: true,
    },
    {
      name: "Prénom",
      selector: (row) =>
        back
          ? row.customer.firstName 
          : row.firstName ,
      sortable: true,
    },
    {
      name: "CIN",
      selector: (row) => row.cin,
      sortable: true,
    },
    {
      name: "Matricule",
      selector: (row) => row.matriculeInclusion,
      sortable: true,
    },
    {
      name: "Produit",
      selector: (row) =>
        back
          ? row.formula.family.familyName + "/" + row.formula.name
          : row.family + "/" + row.formule,
      sortable: true,
    },
    {
      name: "Prix Monture",
      selector: (row) => row.prixAchatMonture,
      sortable: true,
    },
    {
      name: "Prix Verre",
      selector: (row) => row.prixAchatVerre,
      sortable: true,
    },
    {
      name: "Date début",
      selector: (row) => moment(row.startDate).format("DD/MM/yyyy"),
      sortable: true,
    },
    {
      name: "Canal",
      cell: (row) => (
        <Badge
          style={{
            color: "#fff",
            backgroundColor: row.canalVente === "web" ? "#007bff" : "#6c757d",
          }}
        >
          Inclusion
        </Badge>
      ),
    },
    {
      name: "Statut",
      selector: (row) =>
        role === "FrontOffice" ? (
          <></>
        ) : (
          <Badge
            color={
              row.status === "V"
                ? "success"
                : row.status === "C"
                ? "primary"
                : row.status === "E"
                ? "danger"
                : "warning"
            }
          >
            {statusList[row.status]}
          </Badge>
        ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div style={{ display: "flex", flexDirection: "row" }}>
              <IconButton
                className="p-1"
                color="primary"
                size="small"
                onClick={() => {
                  console.log(row);
                  history.push({
                    pathname: "/vueContrat",
                    state: { row, contrat: true, back:true },
                  });
                }}
              >
                <i className="icofont icofont-eye-alt"></i>
              </IconButton>
        </div>
      ),

      button: true,
    },
  ];

  return (
    <div>
      <Breadcrumbs
        parent={"Contrats Inclusion"}
        title={"Liste des inclusions"}
      />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader
            className="ml-2"
            style={{ cursor: "pointer", padding: "10px" }}
            onClick={() => {
              setHandleFilter(!handleFilter);
            }}
          >
            <span className="float-left">
              <h6 className="mt-1">{"Filtrer"}</h6>
            </span>

            <span className="float-right ">
              <h4>
                {handleFilter ? (
                  <i className="fa fa-angle-down"></i>
                ) : (
                  <i className="fa fa-angle-up"></i>
                )}
              </h4>
            </span>
          </CardHeader>

          <CardBody className="pt-0" hidden={back ? true : handleFilter}>
            <Form className="theme-form">
              <Row>
                <Col md="3 mb-2">
                  <FormGroup>
                    <Label className="col-form-label ">Numéro</Label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "numContract")
                      }
                      className="form-control"
                      type="number"
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <Label className="col-form-label ">Cin</Label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "cin")
                      }
                      className="form-control"
                      type="number"
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <Label className="col-form-label "> Nom </Label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "lastName")
                      }
                      className="form-control"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <Label className="col-form-label "> Prénom </Label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "firstName")
                      }
                      className="form-control"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label"> Du</label>
                    <DatePicker
                      className="form-control digits"
                      dateFormat="dd/MM/yyyy"
                      selected={filteredValues.dateDebut || ""}
                      onChange={(e) => {
                        handleFilterValues(e, "dateDebut");
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label"> Au</label>
                    <DatePicker
                      className="form-control digits"
                      dateFormat="dd/MM/yyyy"
                      selected={filteredValues.dateFin || ""}
                      onChange={(e) => {
                        handleFilterValues(e, "dateFin");
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-3">
                  <FormGroup>
                    <Label className="col-form-label">Distributeur</Label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control digits"
                      defaultValue={""}
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "idDistributor")
                      }
                    >
                      <option value={null}>Tout</option>
                      {listDistributors.map((el, index) => (
                        <option key={index} value={el.iddisributor}>
                          {el.socialReason}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="3 mb-3">
                  <FormGroup>
                    <Label className="col-form-label">Statut</Label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control digits"
                      defaultValue={""}
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "status")
                      }
                    >
                      {" "}
                      V: "Validé ", B: "Brouillon", AS: "Attente signature", AP:
                      "Attente paiement", E: "Expirer", C: "Annulé",
                      <option value={""}>{"Tout"}</option>
                      <option value={"V"}>{"Validé"}</option>
                      <option value={"B"}>{"Brouillon"}</option>
                      <option value={"AS"}>{"Attente signature"}</option>
                      <option value={"AP"}>{"Attente paiement"}</option>
                      <option value={"E"}>{"Expirer"}</option>
                      <option value={"C"}>{"Annulé"}</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col>
                <Button
                  onClick={filterData}
                  className="btn-pill btn-air-success"
                  color="success"
                >
                  Recherche
                </Button>
              </Col>
            </Row>
          </CardBody> 
         </Card>

        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader
            className="b-l-primary align-button"
            style={{
              padding: "15px",
              textAlign: "end",
            }}
          >
            <Button
              className="ml-3"
              color="success"
              outline
              onClick={() => {
                Verttoggle();
              }}
            >
              Importation
            </Button>
            <Col className="text-right ">
                <Button
                  className="ml-3"
                  color="success"
                  outline
                  onClick={() => {
                    setLoading(true);
                    if(localStorage.getItem("id") === "6a7d1b9c-254c-455e-a186-6b2a37dd231f"){
                      contractServices
                        .exportexcelwithfilter({
                          cin: "",
                          dateDebut: "",
                          dateFin: "2024-11-28T00:00:00.000Z",
                          famille: "",
                          firstName: "",
                          formula: "",
                          idUser: "4027c224-04fe-4542-be2d-42193bb47407",
                          lastName: "",
                          numContract: "",
                          status: "",
                          tax: "",
                          inclusion: 1,
                        })
                        .then((res) => {
                          downloadXlsx(res.data);
                          setLoading(false);
                        });

                    }else if(role === "Garanty"){
                      contractServices
                        .exportexcelwithfilter({
                          ...filteredValues,
                          dateDebut: moment(filteredValues.dateDebut).add(
                            1,
                            "hour"
                          ),
                          dateFin: moment(filteredValues.dateFin).add(1, "hour"),
  
  
                        })
                        .then((res) => {
                          downloadXlsx(res.data);
                          setLoading(false);
                        });
                    }else{
                      contractServices
                      .exportexcelwithfilter({
                        ...filteredValues,
                        dateDebut: moment(filteredValues.dateDebut).add(
                          1,
                          "hour"
                        ),
                        dateFin: moment(filteredValues.dateFin).add(1, "hour"),
                        sequence: "76 2024 0008",


                      })
                      .then((res) => {
                        downloadXlsx(res.data);
                        setLoading(false);
                      });
                    }
                  }}
                >
                  Exporter
                </Button>
              </Col>
          </CardHeader>

          <CardBody className="pt-2">
            {loading ? (
              <LoadingPage />
            ) : (
              <DataTable
                noHeader
                pagination
                paginationComponentOptions={{
                  rowsPerPageText: "Afficher",
                  rangeSeparatorText: "/",
                  selectAllRowsItem: true,
                  selectAllRowsItemText: "all",
                }}
                columns={columns}
                data={listContractInclusion}
                pointerOnHover={true}
                progressPending=""
                noDataComponent={<div>Aucun enregistrement trouvé</div>}
                progressComponent={
                  <div className="loader-box">
                    <div className="loader-1"></div>
                  </div>
                }
              ></DataTable>
            )}
            <Modal isOpen={Vert} toggle={Verttoggle} centered>
              <Form
                className="needs-validation"
                noValidate=""
                // onSubmit={handleSubmit}
              >
                <ModalHeader toggle={Verttoggle}>Importation des contrats</ModalHeader>
                <ModalBody>
                  <p>Vous êtes sur le point d'importer des contrats inclusion.</p>
                  <Col md="10 mb-4">
                  <Label
                    style={{ fontSize: "16px", fontWeight: "400" }}
                    htmlFor="validationCustom01"
                  >
                    Produit
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Select
                    style={{ height: "50px" }}
                    value={selectOption}
                    onChange={onchangeSelect}
                    options={option}
                  />
                  <span></span>
                </Col>
                  <Col md="10 mb-4">
                    <Input
                      className="form-control"
                      name="reasonStatus"
                      type="file"
                      placeholder="Raison d'annulation"
                      onChange={(e) =>{
                        console.log(e.target.files[0]);
                        setFile(e.target.files[0])}}
                      required={true}
                    />
                  </Col>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" 
                  onClick={()=>{handleSubmit()}}
                  >
                    Continuer
                  </Button>
                </ModalFooter>
              </Form>
            </Modal>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default ContractInclusionList;
