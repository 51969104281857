import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import contractServices from "../../../services/contract/contractServices";
import Breadcrumbs from "../../../layout/breadcrumb";
import download from "downloadjs";
import distributorService from "../../../services/distribitor/distributorService";
import DatePicker from "react-datepicker";
import moment from "moment";
import LoadingPage from "../../../components/LoadingPage";
const VenteGlobal = () => {
  const [contractList, setContractList] = useState();
  const [total, setTotal] = useState();
  const role = localStorage.getItem("role");
  const [loading, setLoading] = useState(false);
  const [listDistributors, setListDistributors] = useState([]);
  const [handleFilter, setHandleFilter] = useState(true);
  const [listContract, setListContract] = useState([]);
  const [listCF, setListCF] = useState([]);
  const [filteredValues, setFilteredValues] = useState(localStorage.getItem("id") === "6a7d1b9c-254c-455e-a186-6b2a37dd231f" ? {
    cin: "",
    dateDebut: "",
    dateFin: "2024-11-28T00:00:00.000Z",
    firstName: "",
    formula: "",
    idUser: "4027c224-04fe-4542-be2d-42193bb47407",
    lastName: "",
    idDistributor: null,
    numContract: "",
    status: "",
    tax: "",
    inclusion: 0,
  }:
  {
    cin: "",
    dateDebut: "",
    dateFin: "",
    famille: "",
    firstName: "",
    formula: "",
    idUser: localStorage.getItem("id"),
    lastName: "",
    numContract: "",
    status: "",
    tax: "",
    inclusion: 0,
  }
);
  useEffect(() => {
    setLoading(true);
    if(localStorage.getItem("id") === "6a7d1b9c-254c-455e-a186-6b2a37dd231f"){
      contractServices
      .filterContract({
        cin: "",
        dateDebut: "",
        dateFin: "2024-11-28T00:00:00.000Z",
        famille: "",
        firstName: "",
        formula: "",
        idUser: "4027c224-04fe-4542-be2d-42193bb47407",
        lastName: "",
        numContract: "",
        status: "",
        tax: "",
        inclusion: 0,
      })
      .then((res) => {
        setContractList(res.data?.filter((el) => el.status !== "C"));
        setLoading(false);
      });
    }
    contractServices
      .filterContract({
        cin: "",
        dateDebut: "",
        dateFin: "",
        famille: "",
        firstName: "",
        formula: "",
        idUser: localStorage.getItem("id"),
        lastName: "",
        numContract: "",
        status: "",
        tax: "",
        inclusion: 0,
      })
      .then((res) => {
        setContractList(res.data?.filter((el) => el.status !== "C"));
        setLoading(false);
      });
  }, []);
  const downloadXlsx = (data) => {
    download(atob(data), "data.xlsx", {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  };
  const handleFilterValues = (value, name) => {
    setFilteredValues({ ...filteredValues, [name]: value });
  };
  const exporter = () => {
    contractServices.exportexcelwithfilter(filteredValues).then((res) => {
      downloadXlsx(res.data);
    });
  };
  const exporterbh = () => {
    setLoading(true);
    if( role === "Garanty" ){
      contractServices.exportBHexcelwithfilter({
        cin: "",
        dateDebut: "",
        dateDebut: null,
        dateFin: null,
        firstName: "",
        formula: "",
        idUser: localStorage.getItem("id"),
        lastName: "",
        idDistributor: null,
        numContract: "",
        status: "",
        tax: "",
        inclusion: 0,
      }).then((res) => {
        if(res.status===200){
          downloadXlsx(res.data);
          setLoading(false);
        }
      });
    }else{
      contractServices.exportBHexcelwithfilter(filteredValues).then((res) => {
        if(res.status===200){
          downloadXlsx(res.data);
          setLoading(false);
        }
      });
    }
  };
  const filterData = async () => {
    let res = [];
    const filtredList = (
      await contractServices.filterContract({
        ...filteredValues,
        dateDebut: moment(filteredValues.dateDebut).add(1, "hour"),
        dateFin: moment(filteredValues.dateFin).add(1, "hour"),
        idDistributor:
          filteredValues.idDistributor === "Tout"
            ? null
            : filteredValues.idDistributor,
      })
    ).data?.filter((el) => el.status !== "C");
    setListCF(filtredList);
    setContractList(filtredList);

    filtredList.forEach((el) => {
      res.push({
        idContract: el.idcontract,
        status: "V",
      });
    });
  };
  const columns = [
    {
      name: "N° Contrat",
      selector: (row) => row.numContract == 0 ? row.numInclusion : row.numContract,
      sortable: true,
    },
    {
      name: "Nom/Prénom",
      selector: (row) => row.lastName + " " + row.firstName,
      sortable: true,
    },
    {
      name: "Prime Net Garanty TTC",
      selector: "netPriceTtc",
      sortable: true,
    },
    // {
    //   name: "Fronting GST TTC",
    //   selector: "frontingHtCourtier",
    //   sortable: true,
    // },
    {
      name: "Fronting La CARTE TTC",
      selector: "frontingHtAssurance",
      sortable: true,
    },
    {
      name: "Marge distributeur TTC",
      selector: "margeDistributeurHt",
      sortable: true,
    },
    {
      name: "TUA",
      selector: "tua",
      sortable: true,
    },
    {
      name: "Prime TTC",
      selector: (row) => (row.totalAmount)?.toFixed(3),
      sortable: true,
    },
  ];
  console.log("hello");
  return (
    <div>
      <Breadcrumbs parent={"État des ventes"} title={"État Global"} />
      <Container fluid={true}>
      <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader
            className="ml-2"
            style={{ cursor: "pointer", padding: "10px" }}
            onClick={() => {
              setHandleFilter(!handleFilter);
            }}
          >
            <span className="float-left">
              <h6 className="mt-1">{"Filtrer"}</h6>
            </span>

            <span className="float-right ">
              <h4>
                {handleFilter ? (
                  <i className="fa fa-angle-down"></i>
                ) : (
                  <i className="fa fa-angle-up"></i>
                )}
              </h4>
            </span>
          </CardHeader>

          <CardBody className="pt-0" hidden={handleFilter}>
            <Form className="theme-form">
              <Row>
                {/* <Col md="3 mb-2">
                  <FormGroup>
                    <Label className="col-form-label ">Num contrat BH</Label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "sequence")
                      }
                       type="select"
                      name="select"
                      className="form-control digits"
                      defaultValue={""}
                    >
                      <option value={""}>{"Tous"}</option>
                      <option value={"2022.202.000001"}>2022.202.000001</option>
                      <option value={"2022.202.000002"}>2022.202.000002</option>
                      <option value={"2022.406.000002"}>2022.406.000002</option>
                      <option value={"2022.406.000003"}>2022.406.000003</option>
                    </Input>
                  </FormGroup>
                </Col> */}
                <Col md="3 mb-2">
                  <FormGroup>
                    <Label className="col-form-label ">Numéro</Label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "numContract")
                      }
                      className="form-control"
                      type="number"
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <Label className="col-form-label ">Cin</Label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "cin")
                      }
                      className="form-control"
                      type="number"
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <Label className="col-form-label "> Nom </Label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "lastName")
                      }
                      className="form-control"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <Label className="col-form-label "> Prénom </Label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "firstName")
                      }
                      className="form-control"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label"> Famille</label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control digits"
                      defaultValue={""}
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "famille")
                      }
                    >
                      <option value={""}>{"Tous"}</option>
                      <option value={"Smartphone"}>Smartphone</option>
                      <option value={"PC Portable"}>PC Portable</option>
                      <option value={"TV"}>TV</option>
                      <option value={"MacBook"}>MacBook</option>
                      <option value={"Tablette"}>Tablette</option>
                      <option value={"Optique"}>Optique</option>
                    </Input>
                  </FormGroup>
                  {/* <FormGroup>
                    <label className="col-form-label"> Canal</label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control digits"
                      defaultValue={0}
                      onChange={(e) => {
                        handleFilterValues(e.target.value, "canalVente");
                      }}
                    >
                      <option value={0}>{"Tous"}</option>
                      <option value={"web"}>Web</option>
                      <option value={"Distributeur"}>Distributeur</option>
                    </Input>
                  </FormGroup> */}
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label"> Canal</label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control digits"
                      defaultValue={0}
                      onChange={(e) => {
                        handleFilterValues(e.target.value, "canalVente");
                      }}
                    >
                      <option value={0}>{"Tous"}</option>
                      <option value={"web"}>Web</option>
                      <option value={"Distributeur"}>Distributeur</option>
                    </Input>
                  </FormGroup>
                </Col>
                {
                  localStorage.getItem("id") === "6a7d1b9c-254c-455e-a186-6b2a37dd231f" ? <></> :
                  <>
                  <Col md="3 mb-2">
                    <FormGroup>
                      <label className="col-form-label"> Du</label>
                      <DatePicker
                        className="form-control digits"
                        dateFormat="dd/MM/yyyy"
                        selected={filteredValues.dateDebut || ""}
                        onChange={(e) => {
                          handleFilterValues(e, "dateDebut");
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3 mb-2">
                    <FormGroup>
                      <label className="col-form-label"> Au</label>
                      <DatePicker
                        className="form-control digits"
                        dateFormat="dd/MM/yyyy"
                        selected={filteredValues.dateFin || ""}
                        onChange={(e) => {
                          handleFilterValues(e, "dateFin");
                        }}
                      />
                    </FormGroup>
                  </Col>
                  </>
                }
               
               
              </Row>
            </Form>
            <Row>
              <Col>
                <Button
                  onClick={filterData}
                  className="btn-pill btn-air-success"
                  color="success"
                >
                  Recherche
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader className="m-0  align-button">
            <Row>
              <Col className="text-right">
                {role === "Assurance " || role === "Garanty" || role === "Courtier "  ? <Button
                  style={{marginRight: "5px"}}
                  className="btn-pill btn-air-primary "
                  color="primary"
                  onClick={exporterbh}
                >
                  {localStorage.getItem("id") === "6a7d1b9c-254c-455e-a186-6b2a37dd231f" ? "Export BH" : "Export La CARTE"}
                </Button> : <></>}
                {/* <Button
                  className="btn-pill btn-air-primary "
                  color="primary"
                  onClick={exporter}
                >
                  {"Exporter"}
                </Button> */}
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="pt-2">
          {loading ? (
              <LoadingPage />
            ) : (
            <DataTable
              noHeader
              pagination
              paginationComponentOptions={{
                rowsPerPageText: "Afficher",
                rangeSeparatorText: "/",
                selectAllRowsItem: true,
                selectAllRowsItemText: "all",
              }}
              columns={columns}
              data={contractList || []}
              pointerOnHover={true}
              progressPending=""
              progressComponent={
                <div className="loader-box">
                  <div className="loader-1"></div>
                </div>
              }
            ></DataTable>
            )}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default VenteGlobal;
